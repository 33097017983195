body {
    height: 100vh;
    /*vh*/
    width: 100vw;
    overflow-y: initial;
    overflow-x: hidden;
}

.form {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.text-box {
    text-align: center;
    padding: 2rem 3rem;
}

#selectableContent {
    display: flex;
    flex-direction: column;
    position: sticky;
}

#selectableContentPan {
    display: flex;
    flex-direction: column;
    position: sticky;
}

#submit-button-container {
    width: 100%;
}

#toggle-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

#nav-btns {
    margin: 1rem;
}

.font-dark {
    color: rgba(0, 0, 0, 0.87);
}

.btn {
    margin: 0.5rem;
    display: table !important;
    word-break: break-all !important;
}

.btn_selection {
    margin: 1rem;
    display: table !important;
    word-break: break-all !important;
    background-color: #20736b !important;
    opacity: 0.5 !important;
}

.btn_preselection {
    margin: 1rem;
    display: table !important;
    word-break: break-all !important;
    background-color: #20736b !important;
}

.btn-wide {
    width: 100%;
}

nav {
    height: 100% !important;
    z-index: 2;
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1;
}

.map {
    grid-column-start: 4;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 12;
    z-index: 1;
}

.ui {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 12;
    overflow: scroll;
    background-color: rgba(230, 230, 230, 0.87);
}

.main-grid {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(11, 1fr);
}

.accordion-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-height: 0;
    overflow: hidden;
    width: 90%;
}

.level-1 {
    width: 80%;
    display: flex;
    align-self: center;
    overflow: auto !important;
}

.level-2 {
    width: 80%;
    height: 100%;
    display: flex;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.level-3 {
    align-self: flex-end;
    overflow: auto !important;
}

.level-4 {
    align-self: flex-end;
    overflow: auto !important;
}

.main-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.main-nav h1 {
    margin: 1rem;
    font-size: 2.5rem;
    color: #26a69a;
    font-weight: 500;
    font-family: sans-serif;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row img {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.toggle_container {
    color: red;
    display: flex;
    flex-direction: row;
}

.greeting {
    grid-column-start: 12;
    grid-row-start: 2;
    z-index: 2;
    opacity: 0.5;
    margin: 0.5rem !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2rem;
    max-height: 3rem;
    right: 1%;
}

.greeting p {
    margin: 1rem;
    text-transform: uppercase;
    font-size: 1rem;
}

.invisible {
    display: none;
    visibility: hidden;
}

.layerlist {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 1%;
    margin-bottom: 1%;
}

.toggle {
    width: 15px;
    height: 15px;
}

.layer {
    margin-left: 0.5rem;
    font-size: 0.8rem !important;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.layerName {
    margin-left: 0.5rem;
    font-size: 0.90rem !important;
    right: 0px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.download-button {
    text-align: center;
    display: flex;
    align-content: center;
    align-content: center;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    cursor: pointer;
}

.download-button:hover {
    color: #ccc;
}

.addDataBox {
    grid-column-start: 12;
    grid-row-start: 11;
    z-index: 2;
    margin: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    min-height: 1rem;
    max-height: 2rem;
}

.registerBtn {
    background-color: white !important;
    color: black !important;
    text-transform: capitalize !important;
    opacity: 0.7;
}

.register {
    background-color: #EEEEEE !important;
    border: 0.6px solid black;
    max-width: 40% !important;
    padding: 3% !important;
}

.downloadForm {
    background-color: #EEEEEE !important;
    border: 0.6px solid black;
    min-width: 50% !important;
    padding: 3% !important;
}

.button {
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.goback {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.manual {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.popover-content {
    min-width: 180px;
}

#wrapper {
    width: 768px;
}

#selectionList {
    width: 50%;
    height: 50%;
    background-color: red;
}